import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AchievementEventDetailedDto,
  SearchAchievementEventsRequestFilters,
  AchievementsEventsStatuses,
  DateSortDirectionAchievementsEvents,
  ListAchievementsEventsFilters,
  AchievementsEventsCountsResponse,
} from "../../api/types/achievementEventsTypes";
import {
  defaultListState,
  defaultStateWithInfiniteLoading,
} from "../../appConsts";
import {
  setInfiniteLoadData,
  setInfiniteLoadLoading,
  setInfiniteLoadStateErrorMessage,
  setStateData,
  setStateError,
  setStateLoading,
} from "../handleUpdateState";
import { AchievementsEventsState } from "../types";
import { logoutUserAction } from "./authReducer";
import { Option } from "utils/types/option";

export const achievementEventsInitialState: AchievementsEventsState = {
  achievementEventsStatsData: defaultListState,
  achievementEventsDropdownsData: defaultListState,
  achievementsEventsData: {
    achievementsEventsList: defaultStateWithInfiniteLoading,
    filters: {
      achievementsEventsStatus: AchievementsEventsStatuses.OnReview,
      achievementsCategoriesIds: null,
      achievementsIds: null,
      userIds: null,
      locations: null,
      departments: null,
      managerIds: null,
      achievementStatus: null,
    },
    dateSortDirection: DateSortDirectionAchievementsEvents.dsc,
  },
};

const achievementsEventsReducer = createSlice({
  initialState: achievementEventsInitialState,
  name: "achievementsEvents",
  reducers: {
    // Achievement events statistics data
    setLoadingAchievementEventsStatistic: (state) => ({
      ...state,
      achievementEventsStatsData: setStateLoading(state.achievementEventsStatsData),
    }),
    setAchievementEventsStatistic: (
      state,
      action: PayloadAction<AchievementsEventsCountsResponse>
    ) => ({
      ...state,
      achievementEventsStatsData: setStateData(
        state.achievementEventsStatsData,
        action.payload
      ),
    }),
    setErrorAchievementEventsStatistic: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      achievementEventsStatsData: setStateError(
        state.achievementEventsStatsData,
        action.payload
      ),
    }),
    // AchievementsEvents dropdowns filters data
    setLoadingAchievementsEventsDropdownData: (state) => ({
      ...state,
      achievementEventsDropdownsData: setStateLoading(
        state.achievementEventsDropdownsData
      ),
    }),
    setAchievementsEventsDropdownData: (
      state,
      action: PayloadAction<Record<ListAchievementsEventsFilters, Option[]>>
    ) => ({
      ...state,
      achievementEventsDropdownsData: setStateData(
        state.achievementEventsDropdownsData,
        action.payload
      ),
    }),
    setErrorAchievementsEventsDropdownData: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      achievementEventsDropdownsData: setStateError(
        state.achievementEventsDropdownsData,
        action.payload
      ),
    }),
    // Achievements Events filters
    setAchievementsEventsFilters: (
      state,
      action: PayloadAction<SearchAchievementEventsRequestFilters>
    ) => ({
      ...state,
      achievementsEventsData: {
        ...state.achievementsEventsData,
        filters: action.payload,
      },
    }),
    // Achievement Events date sorting
    setAchievementsEventsSortDirection: (
      state,
      action: PayloadAction<DateSortDirectionAchievementsEvents>
    ) => ({
      ...state,
      achievementsEventsData: {
        ...state.achievementsEventsData,
        dateSortDirection: action.payload,
      },
    }),
    //  AchievementsEvents list
    setLoadingAchievementsEventsList: (state) => ({
      ...state,
      achievementsEventsData: {
        ...state.achievementsEventsData,
        achievementsEventsList: setInfiniteLoadLoading(
          state.achievementsEventsData.achievementsEventsList
        ),
      },
    }),
    setAchievementsEventsList: (
      state,
      action: PayloadAction<{
        achievementsEventsList: AchievementEventDetailedDto[];
        hasMoreData: boolean;
        page: number;
        isRefreshedList?: boolean;
      }>
    ) => {
      const { hasMoreData, page, isRefreshedList, achievementsEventsList } =
        action.payload;
      let oldAchievementsEventsList =
        state.achievementsEventsData.achievementsEventsList.data || [];
      const sumAchievementsEventsList = isRefreshedList
        ? [...achievementsEventsList]
        : [...oldAchievementsEventsList, ...achievementsEventsList];

      return {
        ...state,
        achievementsEventsData: {
          ...state.achievementsEventsData,
          achievementsEventsList: setInfiniteLoadData(
            state.achievementsEventsData.achievementsEventsList,
            sumAchievementsEventsList,
            hasMoreData,
            page
          ),
        },
      };
    },
    setErrorAchievementsEventsList: (state, action: PayloadAction<string>) => ({
      ...state,
      achievementsEventsData: {
        ...state.achievementsEventsData,
        achievementsEventsList: setInfiniteLoadStateErrorMessage(
          state.achievementsEventsData.achievementsEventsList,
          action.payload
        ),
      },
    }),
    resetAchievementsEventsList: (state) => ({
      ...state,
      achievementsEventsData: {
        ...state.achievementsEventsData,
        achievementsEventsList: {
          ...state.achievementsEventsData.achievementsEventsList,
          page: 0,
          hasMoreData: true,
        },
      },
    }),
    resetAchievementsEventsState: () => achievementEventsInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUserAction, () => achievementEventsInitialState);
  },
});

export default achievementsEventsReducer.reducer;

export const {
  setLoadingAchievementEventsStatistic,
  setAchievementEventsStatistic,
  setErrorAchievementEventsStatistic,
  setLoadingAchievementsEventsDropdownData,
  setAchievementsEventsDropdownData,
  setErrorAchievementsEventsDropdownData,
  setLoadingAchievementsEventsList,
  setAchievementsEventsList,
  setErrorAchievementsEventsList,
  setAchievementsEventsFilters,
  setAchievementsEventsSortDirection,
  resetAchievementsEventsList,
  resetAchievementsEventsState,
} = achievementsEventsReducer.actions;
