import useLocale from "localization/useLocale";
import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "store";
import { getUserAchievementEventsList } from "store/thunks/employeePage/playerProfileThunks";
import { Loader, LoaderSize } from "utils/components";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import { getCropTitle } from "utils/methods/getCropTitle";
import { getDateTimeString } from "utils/methods";
import { useLocation } from "react-router-dom";
import { getRequestStatusNameLocalized } from "utils/methods/getRequestStatusNameLocalized";
import { CategoryLabel } from "utils/components/CategoryLabel";

type Props = {
  userId: number;
};

const UserAchievementsEventsBacklog: FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const locale = useLocale();
  const location = useLocation();
  const targetScrollRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation("playerProfile");
  const { t: tMerchStore } = useTranslation("merchStore");
  const { t: tEvents } = useTranslation("events");
  const { t: tCommon } = useTranslation("common");
  const { isLoading, data, errorMessage, hasMoreData } = useAppSelector(
    (state) => state.playerProfile.userAchievementEvents
  );
  const isCurrentUser = useIsCurrentUser();

  // for navigation from achievementEvent status notification to Quest backlog table
  // implementation from https://javascript.plainenglish.io/creating-a-hash-anchor-link-effect-with-react-router-a63dcb1a9b0e
  const scrollToTarget = () => {
    setTimeout(() => {
      targetScrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  useEffect(() => {
    if (location.state) {
      scrollToTarget();
    }
  }, [data, location.state]);
  const fetchUserAchievementsEventsData = async () => {
    await dispatch(getUserAchievementEventsList(userId));
  };
  return (
    <div className="userAchievementsEventsList" ref={targetScrollRef}>
      <h1 className="main-title m-btm">{t("userAchievementsEventBacklog")}</h1>
      {isLoading && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}
      {errorMessage && <div className="new-wrapper error">{errorMessage}</div>}
      {data && !data.length && (
        <p className="no-content-message">
          {t(
            isCurrentUser
              ? "noUserAchievementsEventsMsg"
              : "noAchievementsEventsMsg"
          )}
        </p>
      )}
      {data && data.length > 0 && (
        <div className="table-wrap">
          <div className="table-inner table-header">
            <span className="item-col item-th">{t("questBacklog_date")}</span>
            <span className="item-col item-th">
              {t("questBacklog_achievement")}
            </span>
            <span className="item-col item-th">
              {tEvents("achievementCategories_label")}
            </span>
            <span className="item-col item-th">
              {t("questBacklog_achievementCoins")}
            </span>
            <span className="item-col item-th">
              {t("questBacklog_achievementEventStatus")}
            </span>
          </div>

          <InfiniteScroll
            dataLength={data.length}
            next={fetchUserAchievementsEventsData}
            hasMore={hasMoreData}
            loader={
              <div className="loader-container">
                <Loader size={LoaderSize.Small} />
              </div>
            }
            endMessage={
              <p className="end-message">
                {tCommon("infiniteScrollEndMessage")}
              </p>
            }
            style={{ overflow: "visible" }}
          >
            {data.map(
              ({
                achievement: { name, categories, coins },
                id,
                createdDate,
                status,
              }) => {
                const achievementCategories = categories.map(
                  ({ id, name, color }) => ({
                    categoryId: id,
                    categoryName: name,
                    categoryColor: color,
                  })
                );
                return (
                  <div
                    className="table-inner new-table-inner userAchievementEvents"
                    key={id}
                  >
                    <div className="item-col">
                      <span className="item-col-first-el">
                        {getDateTimeString(createdDate, "fullDate", locale)}
                      </span>
                    </div>

                    <div className="item-col">
                      <span>{getCropTitle(name, 38)}</span>
                    </div>

                    <div className="item-col">
                      <ul className="userAchievementsEventsList__list-label">
                        {achievementCategories?.map(
                          ({ categoryId, categoryName, categoryColor }) => {
                            return (
                              <CategoryLabel
                                key={categoryId}
                                name={categoryName}
                                color={categoryColor}
                                className="tagLabel-small"
                              />
                            );
                          }
                        )}
                      </ul>
                    </div>

                    <div className="item-col">
                      <span>{tMerchStore("coins", { count: coins })}</span>
                    </div>

                    <div className="item-col">
                      <span>{getRequestStatusNameLocalized(status)}</span>
                    </div>
                  </div>
                );
              }
            )}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default UserAchievementsEventsBacklog;
