import { dateOnly, OptionStringValue } from "utils/types";
import { QueryParams } from "../commonTypes";
import {
  TimeOffPolicyAssignedUsersPageDto,
  TimeOffPolicyAssignPeopleCriteriaDto,
  TimeOffPolicyTypeBaseDto,
} from "./timeOffPolicyTypes";

export enum Unit {
  days = "Days",
}

export enum AccrualCycle {
  monthly = "Monthly",
  annually = "Annually",
}

export enum AccrualTiming {
  cycleStart = "CycleStart",
  multipleHireDate = "MultipleHireDate",
  // cycleEnd = "CycleEnd",
}
export type TimeOffPolicyBasicInfo = {
  name: string;
  description: string;
};

export type TimeOffPolicyAllowance = {
  unit: Unit;
  isUnlimitedAllowance: boolean;
  annualAllowance: number | null;
  accrualCycle: AccrualCycle;
  initialBalance: number;
  annualCarryoverAndGrandDay: dateOnly;
  accrualTiming: AccrualTiming;
  isCarryoverLimitSet: boolean;
  maximumCarryoverLimit: number | null;
};

export type TimeOffPolicyAssignPeople = {
  locations: string[];
};

export type TimeOffPolicyRequest = {
  isNoteMandatory: boolean;
};

export enum TimeOffAssignPeopleFilterType {
  locations = "locations",
}

export enum TimeOffPolicyPositionsType {
  positions = "positions",
}

export type TimeOffPolicyPositionsRequest = Record<
  TimeOffPolicyPositionsType,
  OptionStringValue[]
>;

export type TimeOffPolicyApprovalStep = {
  isApprovalRequired: boolean;
  approvers: ApproversNotificationsRecipientsCreateDto;
  isNotificationRequired: boolean;
  notificationRecipients: ApproversNotificationsRecipientsCreateDto;
};

export type ApproversNotificationsRecipientsCreateDto = {
  positions: string[];
  roleIds: number[];
};

export type ApproversNotificationsRecipientsDto = {
  positions: string[];
  roles: BaseUserRoleDto[];
};

export type TimeOffPolicyApproval = {
  isApprovalRequired: boolean;
  approvers: ApproversNotificationsRecipientsDto;
  isNotificationRequired: boolean;
  notificationRecipients: ApproversNotificationsRecipientsDto;
};

export type BaseUserRoleDto = {
  id: number;
  name: string;
};

export type CreateTimeOffPolicyRequestBody = TimeOffPolicyBasicInfo &
  TimeOffPolicyAllowance &
  TimeOffPolicyRequest &
  TimeOffPolicyApprovalStep & {
    policyTypeId: number;
    assignCriteria: TimeOffPolicyAssignPeople;
  };

export type UpdateTimeOffPolicyRequestBody = CreateTimeOffPolicyRequestBody;

export type TimeOffPolicyDto = TimeOffPolicyAllowance &
  TimeOffPolicyRequest &
  TimeOffPolicyApproval & {
    id: number;
    policyType: TimeOffPolicyTypeBaseDto;
    name: string | null;
    description: string | null;
    assignedUsers: TimeOffPolicyAssignedUsersPageDto;
    assignCriteria: TimeOffPolicyAssignPeopleCriteriaDto;
  };

export type TimeOffAssignPeopleQuery = QueryParams & {
  filters: TimeOffPolicyAssignPeople | null;
};

export type TimeOffPolicyUserDto = {
  id: number;
  firstName: string;
  familyName: string;
  avatarImageUrl: string | null;
  isOnline: boolean;
  position: string | null;
  department: string | null;
  manager: {
    id: number;
    firstName: string;
    familyName: string;
  } | null;
  location: string | null;
};

export type TimeOffPolicyEmployeesResponse = {
  items: TimeOffPolicyUserDto[];
  pageNumber: number;
  totalItems: number;
};

export enum TimeOffPolicyAssignPeopleSortBy {
  Employee = "Employee",
  Department = "Department",
  Manager = "Manager",
  Location = "Location",
}

export type ToggleEnabledTimeOffPolicyRequest = {
  isEnabled: boolean;
};
