import { useEffect, useState } from "react";

export const useConfetti = (isModalOpen: boolean, delay = 120000) => {
  const [isConfettiVisible, setIsConfettiVisible] = useState(false);

  useEffect(() => {
    let confettiTimeout: NodeJS.Timeout | null = null;

    if (isModalOpen) {
      setIsConfettiVisible(true);
      confettiTimeout = setTimeout(() => {
        setIsConfettiVisible(false);
      }, delay);
    } else {
      setIsConfettiVisible(false);
      if (confettiTimeout !== null) {
        clearTimeout(confettiTimeout);
        confettiTimeout = null;
      }
    }

    return () => {
      if (confettiTimeout !== null) {
        clearTimeout(confettiTimeout);
        confettiTimeout = null;
      }
    };
  }, [isModalOpen, delay]);

  return isConfettiVisible;
};
