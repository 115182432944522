import { updateSignificantDates } from "api/employeePageApi";
import { UpdateSignificantDatesRequest } from "api/types/employeePageTypes";
import { AxiosError } from "axios";
import isAfter from "date-fns/isAfter";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import { getUserInfo } from "store/thunks/authThunks";
import { refreshEmployeePageData } from "store/thunks/employeePage/employeePageThunks";
import { serverError } from "types/serverError";
import {
  AggregateErrorMessage,
  ButtonLoader,
  CustomDatePicker,
} from "utils/components";
import RequiredFieldLabel from "utils/components/RequiredFieldLabel";
import { useDisableEditDeleteUser } from "utils/hooks/useDisableEditDeleteUser";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import {
  disableUserFieldForEdit,
  handleServerError,
  toDateOnly,
} from "utils/methods";
import { toastError, toastSuccess } from "utils/toasts";
import * as Yup from "yup";

export type SignificantDatesValues = {
  birthDate: Date | null;
  hireDate: Date | null;
};

type Props = {
  initialValues: SignificantDatesValues;
  userId: number;
  setEditMode: (v: false) => void;
};

const SignificantDatesForm: FC<Props> = ({
  initialValues,
  setEditMode,
  userId,
}) => {
  const dispatch = useAppDispatch();
  const { t: tCommon } = useTranslation("common");
  const { t: tAccountSettings } = useTranslation("accountSettings");
  const isCurrentUser = useIsCurrentUser();
  const { data } = useAppSelector((state) => state.employeePage.employeeData);
  const isEditDisabled = useDisableEditDeleteUser(data?.basicInfo.source);

  const ValidationSchema = Yup.object().shape({
    birthDate: Yup.date()
      .nullable()
      .required(tCommon("validation_fieldIsRequired") as string),
    hireDate: Yup.date()
      .nullable()
      .required(tCommon("validation_fieldIsRequired") as string),
  });
  const handleSubmit = async (
    values: SignificantDatesValues,
    { setErrors }: FormikHelpers<SignificantDatesValues>
  ) => {
    const requestBody: UpdateSignificantDatesRequest = {
      birthDate: toDateOnly(values.birthDate!)!,
      hireDate: toDateOnly(values.hireDate!)!,
    };

    try {
      await updateSignificantDates(userId, requestBody);
      toastSuccess(tAccountSettings("userUpdatedToast"));
      dispatch(refreshEmployeePageData(userId));
      if (isCurrentUser) {
        dispatch(getUserInfo(userId));
      }
      setEditMode(false);
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      toastError(error?.message || tCommon("incorrectData"));
      setErrors(handleServerError(error || {}));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
    >
      {(props: FormikProps<SignificantDatesValues>) => {
        const { values, isSubmitting } = props;

        return (
          <Form className="form-style" autoComplete="off">
            <AggregateErrorMessage component="div" className="error" />
            <div className="employeePage__item">
              <label htmlFor="hireDate" className="employeePage__label ">
                <RequiredFieldLabel
                  labelTitle={tAccountSettings("userForm_hireDate")}
                />
              </label>
              <div className="significantDatesBlock__hireDate">
                <Field
                  name="hireDate"
                  component={CustomDatePicker}
                  disabledDate={(date: Date) => {
                    if (!values.birthDate) {
                      return false;
                    }

                    return date <= values.birthDate;
                  }}
                  placement="topStart"
                  disabled={disableUserFieldForEdit("hireDate", isEditDisabled)}
                />
                <ErrorMessage
                  component="div"
                  name="hireDate"
                  className="error"
                />
              </div>
              <label htmlFor="birthDate" className="employeePage__label">
                <RequiredFieldLabel
                  labelTitle={tAccountSettings("userForm_birthDate")}
                />
              </label>
              <div>
                <Field
                  name="birthDate"
                  component={CustomDatePicker}
                  disabledDate={(date: Date) => isAfter(date, new Date())}
                  placement="topStart"
                  disabled={disableUserFieldForEdit(
                    "birthDate",
                    isEditDisabled
                  )}
                />
                <ErrorMessage
                  component="div"
                  name="birthDate"
                  className="error"
                />
              </div>
            </div>

            <div className="formButtons">
              <button
                type="button"
                className="btn-tertiary formButtons__formBtn"
                disabled={isSubmitting}
                onClick={() => setEditMode(false)}
              >
                {tCommon("form_cancel")}
              </button>
              <button
                type="submit"
                className="btn-primary formButtons__formBtn"
                disabled={isSubmitting}
              >
                {isSubmitting ? <ButtonLoader /> : tCommon("form_save")}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignificantDatesForm;
