import { BadgeMessageSystem } from "api/types/news";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "rsuite";
import { useWindowSize } from "utils/hooks/useWindowSize";
import Icon from "../Icon";
import { CelebrationConfetti } from "./CelebrationConfetti";
import { CelebrationType } from "./CelebrationModal";
import { useConfetti } from "./hooks/useConfetti";

type Step2Props = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  type: CelebrationType | null;
  event: BadgeMessageSystem | null;
};

export const Step2: FC<Step2Props> = ({
  isModalOpen,
  onCloseModal,
  type,
  event,
}) => {
  const { t } = useTranslation("celebrationModal");
  const { t: tMerch } = useTranslation("merchStore");
  const birthdayType = type === CelebrationType.birthday;
  const isConfettiVisible = useConfetti(isModalOpen);
  const { width } = useWindowSize();

  if (!event) return null;

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={onCloseModal}
        className="modal celebrationModal"
        size={width > 1400 ? "lg" : "sm"}
      >
        <Modal.Header>
          <button
            className="modalCloseBtn"
            type="button"
            onClick={onCloseModal}
          >
            <Icon href="#close-popup" svgClass="ic-close-popup" />
          </button>
          <div className="d-flex-center">
            <h1 className="main-title">
              {t(`${birthdayType ? "title_birthday" : "title_anniversary"}`)}
            </h1>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="celebrationModal__text">{event?.message}</div>
          <img
            className="celebrationModal__img-coins"
            src="/img/star-coins.png"
            alt=""
          />
          <div className="celebrationModal__coins">
            + {tMerch("coins", { count: event?.coins ? +event?.coins : 0 })}
          </div>
          <button className="btn-primary" type="button" onClick={onCloseModal}>
            {t("getCoins")}
          </button>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {isConfettiVisible && <CelebrationConfetti />}
    </>
  );
};
