import { FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import Select, {
  GroupBase,
  MultiValue,
  Options,
  SingleValue,
  StylesConfig,
} from "react-select";
import { Option } from "../types";
import { CustomOption } from "./CheckSelectedSelectOption";
import { ReactNode } from "react";
import { SelectComponents } from "react-select/dist/declarations/src/components";

interface CustomSelectProps extends FieldProps {
  options: Options<Option>;
  isMulti?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  placeholder?: string | ReactNode;
  handleChange?: () => void;
  selectClassName?: string;
  customStyles?: StylesConfig<Option>;
  onBlur?: () => void;
  customComponents?: Partial<
    SelectComponents<Option, boolean, GroupBase<Option>>
  >;
}

export const CustomSelect = ({
  field,
  form,
  options,
  isMulti = false,
  isClearable = false,
  isSearchable = false,
  isDisabled = false,
  placeholder,
  handleChange,
  selectClassName,
  customStyles,
  onBlur,
  customComponents = { Option: CustomOption },
}: CustomSelectProps) => {
  const { t: tCommon } = useTranslation("common");

  const onChange = (option: SingleValue<Option> | MultiValue<Option>) => {
    // handleChange call must be before form.setFieldValue for prevent strange validation behavior
    // (submit empty form --> validation errors --> chose value on select --> error doesn't disappear)
    if (handleChange) {
      handleChange();
    }

    form.setFieldValue(
      field.name,
      option
        ? isMulti
          ? (option as Option[]).map((item: Option) => item.value)
          : (option as Option).value
        : ""
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value) || "";
    } else {
      return isMulti ? [] : ("" as any);
    }
  };

  return (
    <Select
      name={field.name}
      value={getValue()}
      onChange={onChange}
      options={options}
      components={customComponents}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isMulti={isMulti}
      isDisabled={isDisabled}
      className={`select__container ${selectClassName}`}
      classNamePrefix="select"
      placeholder={placeholder}
      noOptionsMessage={() => <>{tCommon("select_noItems") as string}</>}
      styles={customStyles}
      onBlur={onBlur}
      filterOption={
        isSearchable
          ? (option: Option, inputValue: string) => {
              // Filter by label only
              return option.label
                .toLowerCase()
                .includes(inputValue.toLowerCase());
            }
          : undefined
      }
    />
  );
};

export default CustomSelect;
