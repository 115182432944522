import classNames from "classnames";
import isNumber from "lodash/isNumber";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store";
import { Icon, Loader, LoaderSize } from "../../../utils/components";
import { useIsCurrentUser } from "../../../utils/hooks/useIsCurrentUser";
import AddEditTimeOffRequest from "./addEditTimeOffRequest/AddEditTimeOffRequest";

const CurrentBalancesBlock = () => {
  const { t } = useTranslation("timeOffProfile");
  const { t: tLayout } = useTranslation("layout");

  const scroller = useRef<HTMLDivElement | null>(null);
  const [leftScrollDisabled, setLeftScrollDisabled] = useState(true);
  const [rightScrollDisabled, setRightScrollDisabled] = useState(false);

  const isCurrentUser = useIsCurrentUser();
  const { isLoading, data, errorMessage } = useAppSelector(
    (state) => state.timeOffProfile.currentBalances
  );
  const [isShowRequestModal, setIsShowRequestModal] = useState(false);

  const isShowRequestBtn = Boolean(data && data.length > 0 && isCurrentUser);

  const handleRequestModal = () => {
    setIsShowRequestModal((prev) => !prev);
  };

  useEffect(() => {
    const element = scroller?.current as HTMLDivElement;
    const handleScroll = () => {
      const { scrollLeft, clientWidth, scrollWidth } = element;
      setLeftScrollDisabled(!scrollLeft);
      setRightScrollDisabled(scrollLeft + clientWidth === scrollWidth);
    };

    element?.addEventListener("scroll", handleScroll);

    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, [data]);

  return (
    <section className="customCarousel userTimeOffBalances">
      <div className="d-flex userTimeOffBalances__menu">
        <h1 className="main-title">{t("currentBalances")}</h1>
        {isShowRequestBtn && (
          <button
            type="button"
            className="btn-primary"
            onClick={handleRequestModal}
          >
            {tLayout("requestTimeOff")}
          </button>
        )}
      </div>

      {isLoading && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}

      {errorMessage && <div className="new-wrapper error">{errorMessage}</div>}

      {data && data.length === 0 && (
        <p className="no-content-message">
          {isCurrentUser
            ? t("currentBalances_emptyData_user")
            : t("currentBalances_emptyData_admin")}
        </p>
      )}

      {data && data.length > 0 && (
        <div
          className={classNames("customCarousel__carousel", {
            carouselLeftScroll: !leftScrollDisabled,
            carouselRightScroll: !rightScrollDisabled,
          })}
        >
          <div className="customCarousel__wrap" ref={scroller}>
            {data.map(({ id, policyType, daysAvailable, daysUsed }) => {
              const { name, iconKey } = policyType;
              const usedTimeOffLabel = `${daysUsed} ${t("balanceDays", {
                count: daysUsed,
              })}`;

              const formattedDaysAvailable = Number.isInteger(daysAvailable)
                ? daysAvailable
                : daysAvailable?.toFixed(1);

              const count = formattedDaysAvailable
                ? parseFloat(formattedDaysAvailable.toString())
                : 0;

              return (
                <div className="box timeOffBalanceItem" key={id}>
                  <div className="d-flex timeOffBalanceItem__policyInfo">
                    <Icon
                      href={`#${iconKey}`}
                      svgClass="timeOffBalanceItem__icon"
                    />

                    <h3 className="timeOffBalanceItem__policyName">{name}</h3>
                  </div>

                  <p className="timeOffBalanceItem__balance">
                    {isNumber(daysAvailable) ? (
                      <>
                        <span className="timeOffBalanceItem__balanceCount">
                          {formattedDaysAvailable}
                        </span>
                        <span className="timeOffBalanceItem__balanceLabel">
                          {t("balanceDays", {
                            count: count,
                          })}
                        </span>
                      </>
                    ) : (
                      <>
                        <Icon
                          href="#infinity"
                          svgClass="timeOffBalanceItem__infinityIcon"
                        />
                        <span className="timeOffBalanceItem__balanceLabel infinityBalance">
                          {t("balanceDaysUnlimited")}
                        </span>
                      </>
                    )}
                  </p>

                  <p className="timeOffBalanceItem__usedUnits">
                    <span className="timeOffBalanceItem__usedAnnualAllowance">
                      {usedTimeOffLabel}
                    </span>
                    <span>{t("usedLabel")}</span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <AddEditTimeOffRequest
        isModalOpen={isShowRequestModal}
        onCloseModal={handleRequestModal}
      />
    </section>
  );
};

export default CurrentBalancesBlock;
