import { AxiosError } from "axios";
import classNames from "classnames";
import useLocale from "localization/useLocale";
import SendCheersRightPanel from "pages/employeeCard/employee-achievements/SendCheersRightPanel";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { updateCompanyCoverImg } from "../../api/profileApi";
import { useAppDispatch, useAppSelector } from "../../store";
import { resetNewsState } from "../../store/reducers/newsReducer";
import { getProfile } from "../../store/thunks/authThunks";
import {
  changeNewsTab,
  getHomePageData,
  getMyTeamEventsByMonth,
} from "../../store/thunks/newsThunks";
import { Tabs } from "../../store/types";
import { serverError } from "../../types/serverError";
import CoverImage from "../../utils/components/CoverImage";
import { AppPermissions, Features } from "../../utils/consts";
import { useCompanyFeatureCheck, usePermissionCheck } from "../../utils/hooks";
import { getDateTimeString } from "../../utils/methods";
import { toastError, toastSuccess } from "../../utils/toasts";
import { NewsCalendar } from "./Calendar";
import Essentials from "./Essentials/Essentials";
import NewsTabContent from "./NewsTabContent";

type Tab = {
  value: Tabs;
  label: string;
  className: string;
};

const NewsPage: FC = () => {
  const { t } = useTranslation("news");
  const { t: tCommon } = useTranslation("common");
  const { profile } = useAppSelector((state) => state.auth);
  const locale = useLocale();
  const location = useLocation();

  const [validationCoverError, setValidationCoverError] = useState<
    null | string[]
  >(null);
  const [isSendCheersModalOpen, setIsSendCheersModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(
    (state) => state.news.messageData.selectedTab
  );
  const managerID = useAppSelector((state) => state.auth.user?.managerId);

  const isAdminForChangeCompanyCoverImg = usePermissionCheck([
    AppPermissions.ChangeCompanysLogoAndColors,
  ]);
  const isGamificationAvailable = useCompanyFeatureCheck(Features.Gamification);
  const currentUserId = useAppSelector((state) => state.auth.profile?.id);

  const currentDate = new Date().toISOString();
  const currentMonth = getDateTimeString(currentDate, "monthDate", locale);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailActionSendCheers = params.get("action") === "send_cheers";
    if (emailActionSendCheers) {
      setIsSendCheersModalOpen(true);
    }

    dispatch(getHomePageData(isGamificationAvailable, currentMonth));

    return () => {
      dispatch(resetNewsState());
    };
  }, [currentMonth, dispatch, isGamificationAvailable, location.search]);

  useEffect(() => {
    const validManagerID = managerID ?? null;
    const validUserID = currentUserId ?? null;
    dispatch(getMyTeamEventsByMonth(validManagerID, currentMonth, validUserID));
  }, [managerID, dispatch, currentMonth, currentUserId]);

  const tabs: Tab[] = [
    {
      value: Tabs.myTeam,
      label: t("mode_myTeam"),
      className: "pageTabs__link",
    },
    { value: Tabs.all, label: t("mode_all"), className: "pageTabs__link" },
  ];

  const saveCompanyCoverImg = async (croppedImg: string) => {
    const result = await fetch(croppedImg);
    const blob = await result.blob();
    const formData: FormData = new FormData();
    formData.append(
      "companyCoverImage",
      blob,
      `companyCoverImg${profile?.companyId || ""}.png`
    );

    try {
      await updateCompanyCoverImg(formData);
      dispatch(getProfile());
      toastSuccess(tCommon("updateCoverImage"));
    } catch (e) {
      const axiosError = e as AxiosError;
      const error = axiosError.response?.data as serverError;
      if (error.errors) {
        setValidationCoverError(error.errors?.companyCoverImage);
      }
      toastError(error?.message || tCommon("errorCoverImage"));
    }
  };

  return (
    <>
      <CoverImage
        uploadedImgPreview={
          profile?.companyCoverImageUrl || "/img/defaultCover.png"
        }
        saveUploadedImage={saveCompanyCoverImg}
        isEditBtn={isAdminForChangeCompanyCoverImg}
      />

      {validationCoverError && (
        <div className="m-btm">
          {validationCoverError.map((error) => (
            <p key={error} className="error">
              {error}
            </p>
          ))}
        </div>
      )}

      <div className="content">
        <div className="news">
          <div className="news-head">
            <h1 className="main-title">{t("newsTitle")}</h1>

            <ul className="pageTabs">
              {tabs.map((tab) => (
                <li
                  key={tab.value}
                  className={classNames(tab.className, {
                    selected: tab.value === selectedTab,
                  })}
                  onClick={() => dispatch(changeNewsTab(tab.value))}
                >
                  {tab.label}
                </li>
              ))}
            </ul>
          </div>
          <NewsTabContent />
        </div>
        <div className="news-sideContent">
          <NewsCalendar />
          {isGamificationAvailable && <Essentials />}
        </div>
        <SendCheersRightPanel
          isModalOpen={isSendCheersModalOpen}
          onCloseModal={() => {
            setIsSendCheersModalOpen(false);
          }}
        />
      </div>
    </>
  );
};

export default NewsPage;
