import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { Icon, Loader, LoaderSize } from "utils/components";
import { AppPermissions } from "utils/consts";
import { usePermissionCheck } from "utils/hooks";
import { parseDateFromString } from "../../../../utils/methods/parseDateFromString";
import SignificantDatesForm, {
  SignificantDatesValues,
} from "./SignificantDatesForm";
import SignificantDatesInfo from "./SignificantDatesInfo";

const SignificantDatesBlock = () => {
  const { t } = useTranslation("employeePage");
  const { isLoading, data, errorMessage } = useAppSelector(
    (state) => state.employeePage.employeeData
  );
  const isUserHavePermissions = usePermissionCheck([
    AppPermissions.ViewAndEditUserEmployeeCards,
  ]);

  const [editMode, setEditMode] = useState(false);
  const initialFormValues: SignificantDatesValues = {
    birthDate: data?.dates.birthDate
      ? parseDateFromString(data.dates.birthDate)
      : null,
    hireDate: data?.dates.hireDate
      ? parseDateFromString(data.dates.hireDate)
      : null,
  };
  const isHaveAccessToEdit = data && isUserHavePermissions;

  return (
    <div className="significantDatesBlock">
      <div className="pageTitleBlock">
        <h1 className="main-title">{t("significantDates_title")}</h1>

        {isHaveAccessToEdit && (
          <button
            type="button"
            className="btn-tertiary actionBtn-tertiary"
            onClick={() => setEditMode((prevState) => !prevState)}
          >
            <Icon
              href={editMode ? "#close-popup" : "#edite"}
              svgClass="buttonIcon"
            />
          </button>
        )}
      </div>
      {isLoading && (
        <div className="loader-container">
          <Loader size={LoaderSize.Small} />
        </div>
      )}
      {errorMessage && <div className="new-wrapper error">{errorMessage}</div>}
      {data && (
        <>
          {editMode ? (
            <SignificantDatesForm
              initialValues={initialFormValues}
              userId={data.id}
              setEditMode={setEditMode}
            />
          ) : (
            <SignificantDatesInfo datesInfo={data.dates} />
          )}
        </>
      )}
    </div>
  );
};

export default SignificantDatesBlock;
