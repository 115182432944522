import classNames from "classnames";
import useLocale from "localization/useLocale";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "rsuite";
import { useWindowSize } from "utils/hooks/useWindowSize";
import Icon from "../Icon";
import { CelebrationType } from "./CelebrationModal";

type Step1Props = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  nextStep: () => void;
  type: CelebrationType | null;
};

export const Step1: FC<Step1Props> = ({
  isModalOpen,
  onCloseModal,
  nextStep,
  type,
}) => {
  const locale = useLocale();
  const { t } = useTranslation("celebrationModal");
  const { width } = useWindowSize();

  const isEnLang = locale.code === "en";
  const birthdayType = type === CelebrationType.birthday;

  return (
    <Modal
      open={isModalOpen}
      onClose={onCloseModal}
      className="modal celebrationModal"
      size={width > 1400 ? "lg" : "sm"}
    >
      <Modal.Header>
        <button className="modalCloseBtn" type="button" onClick={onCloseModal}>
          <Icon href="#close-popup" svgClass="ic-close-popup" />
        </button>
        <div className="d-flex-center">
          <h1
            className={classNames("main-title", {
              "main-title--m-width": birthdayType && isEnLang,
              "main-title--max-width": !isEnLang,
            })}
          >
            {t(`${birthdayType ? "title_birthday" : "title_anniversary"}`)}
          </h1>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="celebrationModal__text">
          {t(`${birthdayType ? "subtitle_birthday" : "subtitle_anniversary"}`)}
        </div>
        <img
          className={classNames("celebrationModal__img", {
            "celebrationModal__img--margin": !birthdayType,
          })}
          src={`/img/${birthdayType ? "gift-box" : "star"}.png`}
          alt=""
        />
        <button className="btn-primary" onClick={nextStep}>
          {t(`${birthdayType ? "openBox" : "makeWish"}`)}
        </button>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
