import { achievementCategoryDefaultColor } from "appConsts";
import { FC } from "react";
import { Tooltip, Whisper } from "rsuite";
import { Icon } from "utils/components";
import { getCropTitle } from "utils/methods/getCropTitle";

const maxLengthCategoryName = 20;

type CategoryLabelProps<T = any> = {
  color: string;
  name: string;
  handleNavigate?: (data: T) => void;
  handleClose?: (data: T) => void;
  isCloseIcon?: boolean;
  isFullTitle?: boolean;
  className?: string;
};

export const CategoryLabel: FC<CategoryLabelProps> = ({
  color,
  name,
  handleClose,
  handleNavigate,
  isCloseIcon = false,
  isFullTitle = false,
  className,
}) => {
  return (
    <li
      className={`tagLabel ${className} d-flex tagLabel--${
        color || achievementCategoryDefaultColor
      }`}
      onClick={handleNavigate}
    >
      {!isFullTitle && name.length > maxLengthCategoryName ? (
        <Whisper
          speaker={
            <Tooltip className="custom-tooltip custom-tooltip-white">
              {name}
            </Tooltip>
          }
          placement="topStart"
          trigger="hover"
        >
          <p>{isFullTitle ? name : getCropTitle(name, maxLengthCategoryName)}</p>
        </Whisper>
      ) : (
        <p>{name}</p>
      )}

      {isCloseIcon && (
        <button className="d-flex" onClick={handleClose}>
          <Icon
            href="#close-popup"
            svgClass="ic-close-popup tagLabelCloseIcon"
          />
        </button>
      )}
    </li>
  );
};
