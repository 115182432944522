import { DeltaStatic } from "quill";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DateRange } from "rsuite/esm/DateRangePicker";

import LayoutRightPanel from "pages/layout/LayoutRightPanel";
import { useAppDispatch, useAppSelector } from "store";
import { resetTimeOffRequest } from "store/reducers/employeePageReducers/timeOffProfileReducer";
import {
  getTimeOffRequestById,
  getUserTimeOffBalances,
} from "store/thunks/employeePage/timeOffProfileThunks";
import Loader, { LoaderSize } from "utils/components/Loader";
import { AppPermissions } from "utils/consts";
import { usePermissionCheck } from "utils/hooks";
import { useIsCurrentUser } from "utils/hooks/useIsCurrentUser";
import { parseDateFromString } from "utils/methods/parseDateFromString";
import { safeJsonParse } from "utils/methods/safeJsonParse";
import AddEditTimeOffRequestForm from "./AddEditTimeOffRequestForm";

const initialTimeOffRequestValues = {
  timeOffBalanceId: null,
  dateRange: null,
  note: "",
};

export type TimeOffRequestValues = {
  timeOffBalanceId: number | null;
  dateRange: DateRange | null;
  note: DeltaStatic | string;
};

type AddEditTimeOffRequestProps = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  requestId?: number | null;
  requestUserId?: number | null; // we need this props for receive request by id on Request management page
};

const AddEditTimeOffRequest: FC<AddEditTimeOffRequestProps> = ({
  isModalOpen,
  onCloseModal,
  requestId,
  requestUserId,
}) => {
  const { userId } = useParams();
  const { t } = useTranslation("timeOffProfile");

  const { profile } = useAppSelector((state) => state.auth);
  const currentUserId = profile?.id;

  const dispatch = useAppDispatch();
  const { isLoading, data, errorMessage } = useAppSelector(
    (state) => state.timeOffProfile.currentBalances
  );

  const isEditTimeOffRequest = usePermissionCheck([
    AppPermissions.TimeOff_ViewAndManageOtherUsersRequests,
  ]);

  const [initialValues, setInitialValues] = useState<TimeOffRequestValues>(
    initialTimeOffRequestValues
  );

  const isCurrentUser = useIsCurrentUser();

  const isRequestEdit = requestId && (isCurrentUser || isEditTimeOffRequest);

  const {
    isLoading: isLoadingRequest,
    data: requestData,
    errorMessage: requestError,
  } = useAppSelector((state) => state.timeOffProfile.requestData);
  const { policyType, fromDate, toDate, note, balance } = requestData || {};

  const getPolicyTypeOptions = useCallback(() => {
    // options for edit request (select disabled, so we set one previous chosen option)
    if (balance?.id && policyType) {
      return [
        {
          value: balance.id,
          label: policyType.name,
        },
      ];
    }

    return data
      ? data.map(({ id, policyType }) => ({
          value: id,
          label: policyType.name,
        }))
      : [];
  }, [data, requestData]);

  const isGetUserTimeOffData =
    isModalOpen && currentUserId && !data && !requestId;

  const getInitialValuesForEdit = useCallback(() => {
    if (!isRequestEdit) return;

    let dateRange: DateRange | null = null;

    if (fromDate && toDate) {
      dateRange = [parseDateFromString(fromDate), parseDateFromString(toDate)];
    }
    setInitialValues({
      timeOffBalanceId: balance?.id || null,
      dateRange,
      note: safeJsonParse(note || ""),
    });
  }, [isRequestEdit, fromDate, toDate, balance, note]);

  const fetchData = useCallback(async () => {
    // we edit request on profile (take userId from params) or on RequestManagementPage (take userId from props)
    const creatorUserId = userId || requestUserId;
    try {
      await Promise.all([
        isRequestEdit && isModalOpen && creatorUserId
          ? dispatch(getTimeOffRequestById(+creatorUserId, requestId))
          : Promise.resolve(),
        isGetUserTimeOffData
          ? // if we create request and render modal not only on profile page we must get current user balances info
            dispatch(getUserTimeOffBalances(currentUserId))
          : Promise.resolve(),
      ]);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  }, [
    isRequestEdit,
    isModalOpen,
    dispatch,
    requestId,
    isGetUserTimeOffData,
    userId,
  ]);

  useEffect(() => {
    fetchData();

    return () => {
      if (isRequestEdit) {
        dispatch(resetTimeOffRequest());
      }
    };
  }, [dispatch, isRequestEdit, fetchData]);

  useEffect(() => {
    if (requestId && requestData) {
      getInitialValuesForEdit();
    }
  }, [requestId, requestData, getInitialValuesForEdit]);

  const handleClose = () => {
    setInitialValues(initialTimeOffRequestValues);

    onCloseModal();
  };

  const policyTypesOptions = getPolicyTypeOptions();

  return (
    <LayoutRightPanel
      isModalOpen={isModalOpen}
      onCloseModal={handleClose}
      title={
        requestId ? t("timeOffRequestEditTitle") : t("timeOffRequestTitle")
      }
      additionalClassName={"popup-badge timeOffRequestPopup"}
    >
      {isModalOpen ? (
        <>
          {isLoading || isLoadingRequest ? (
            <div className="loader-container">
              <Loader size={LoaderSize.Page} />
            </div>
          ) : errorMessage || requestError ? (
            <div className="new-wrapper error">
              {errorMessage || requestError}
            </div>
          ) : (
            <AddEditTimeOffRequestForm
              initialValues={initialValues}
              onCloseModal={handleClose}
              policyTypeOptions={policyTypesOptions}
              requestId={requestId}
            />
          )}
        </>
      ) : null}
    </LayoutRightPanel>
  );
};

export default AddEditTimeOffRequest;
