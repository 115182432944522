import { AxiosPromise } from "axios";
import { base_url, countItemsPerPage } from "../appConsts";
import { axiosInstance } from "./instance";
import {
  BadgeMessageSystemResponse,
  EssentialsAchievementsResponse,
  EssentialsMerchResponse,
} from "./types/news";
import { UsersListResponse } from "./types/usersTypes";

export const loadAllBadgeMessages = (
  pageNumber: number
): AxiosPromise<BadgeMessageSystemResponse> =>
  axiosInstance.get(
    `${base_url}/api/badge-messages/all?pageNumber=${pageNumber}&pageSize=${countItemsPerPage}`
  );

export const loadMyTeamBadgeMessages = (
  pageNumber: number
): AxiosPromise<BadgeMessageSystemResponse> =>
  axiosInstance.get(
    `${base_url}/api/badge-messages/my-team?pageNumber=${pageNumber}&pageSize=${countItemsPerPage}`
  );

export const loadEssentialsAchievements = (
  pageNumber: number
): AxiosPromise<EssentialsAchievementsResponse> => {
  const skipItemsCount = (pageNumber - 1) * countItemsPerPage;
  const currentDate = new Date().toISOString();

  return axiosInstance.get(
    `${base_url}/api/achievements/odata/?$filter=IncludeInNewsFeed eq true and isActive eq true and StartDate le ${currentDate}&$top=${countItemsPerPage}&$skip=${skipItemsCount}&$count=true`
  );
};

export const loadEssentialsMerch = (
  pageNumber: number
): AxiosPromise<EssentialsMerchResponse> => {
  const skipItemsCount = (pageNumber - 1) * countItemsPerPage;

  return axiosInstance.get(
    `${base_url}/api/merch/odata?$filter=IsPromoted eq true and QuantityInfo/CurrentQuantity gt 0&$top=${countItemsPerPage}&$skip=${skipItemsCount}&$count=true&$orderby=CreatedDate desc`
  );
};

export const loadMyTeamEventsByMonth = (
  managerID: number | null,
  currentMonth: string,
  currentUserId: number
): AxiosPromise<UsersListResponse> => {
  const managerFilter = managerID
    ? `ManagerId eq ${managerID} or Id eq ${managerID} or`
    : "";
  const filterQuery = `(${managerFilter} ManagerId eq ${currentUserId} or Id eq ${currentUserId}) 
    and (month(BirthDate) eq ${currentMonth} or month(HireDate) eq ${currentMonth})`;

  return axiosInstance.get(
    `${base_url}/api/users/odata?$filter=${filterQuery}`
  );
};

export const loadAllEventsEndingByMonth = (
  currentMonth: string
): AxiosPromise =>
  axiosInstance.get(
    `${base_url}/api/achievements/odata?$filter=(month(ActiveUntil) eq ${currentMonth}) and isActive eq true or (isActive eq false and VisibilityType eq 'Visible')`
  );
