import {
  AchievementCategoryBaseDto,
  AchievementDto,
  AchievementStatus,
} from "./achievementTypes";
import { ListDto, PageDto, QueryParams } from "./commonTypes";
import { UserOfSearchWithActiveStatus } from "./usersTypes";

export type AchievementEventDocumentDto = {
  id: number;
  isImage: boolean;
  fileUrl: string;
};

export type AchievementEventDto = {
  id: number;
  coins: number;
  status: string;
  createdDate: string;
  achievement: AchievementDto;
  documents: AchievementEventDocumentDto[];
};

export type AchievementCategorySearchDto = {
  id: number;
  name: string;
};

export type AchievementSearchDto = {
  id: number;
  name: string;
  categories: AchievementCategoryBaseDto[];
};

export type AchievementCategoriesSearchListDto =
  ListDto<AchievementCategorySearchDto>;

export type AchievementsSearchListDto = ListDto<AchievementSearchDto>;

export type AchievementsEventsCountsResponse = {
  onReviewCount: number;
  approvedCount: number;
  rejectedCount: number;
}

export type CreateAchievementEventRequestBody = {
  achievementId: number;
  coins: number;
  description: string;
};

export type CreateAchievementEventResponse = {
  event: AchievementEventDto;
};

export enum AchievementsEventsStatuses {
  OnReview = "OnReview",
  Approved = "Approved",
  Rejected = "Rejected",
}

export type AchievementEventDetailedDto = {
  id: number;
  coins: number;
  status: AchievementsEventsStatuses | string;
  createdDate: Date | string;
  achievement: AchievementDto;
  documents: AchievementEventDocumentDto[];
  description: string | null;
  creatorUser: UserOfSearchWithActiveStatus;
};

export enum ListAchievementsEventsFilters {
  achievementsCategoriesIds = "achievementsCategoriesIds",
  achievementsIds = "achievementsIds",
  locations = "locations",
  departments = "departments",
}

export enum MainAchievementsEventsFilters {
  achievementStatus = "achievementStatus",
  userIds = "userIds",
  managerIds = "managerIds",
  achievementsCategoriesIds = "achievementsCategoriesIds",
  achievementsIds = "achievementsIds",
  locations = "locations",
  departments = "departments",
}

export type SearchAchievementEventsRequestFilters = {
  [MainAchievementsEventsFilters.achievementsCategoriesIds]: number[] | null;
  [MainAchievementsEventsFilters.achievementsIds]: number[] | null;
  [MainAchievementsEventsFilters.achievementStatus]: AchievementStatus | null;
  [MainAchievementsEventsFilters.departments]: string[] | null;
  [MainAchievementsEventsFilters.locations]: string[] | null;
  [MainAchievementsEventsFilters.userIds]: number[] | null;
  [MainAchievementsEventsFilters.managerIds]: number[] | null;
  achievementsEventsStatus: AchievementsEventsStatuses | null;
};

export enum DateSortDirectionAchievementsEvents {
  asc = "asc",
  dsc = "desc",
}

export type SearchAchievementsEventsRequest = {
  filters: SearchAchievementEventsRequestFilters | null;
  dateSortDirection: DateSortDirectionAchievementsEvents;
  pageNumber: number;
  pageSize: number;
};

export type GetEventsForApprovalResponse = {
  items: AchievementEventDetailedDto[];
  pageNumber: number;
  totalItems: number;
};

export enum AchievementsStatuses {
  all = "All",
  open = "OpenToApply",
  planned = "Planned",
  alreadyIn = "Applied",
  hidden = "HiddenUntilStart",
}

export type AchievementsResponse = PageDto<AchievementDto>;

export type AchievementsQuery = Omit<QueryParams, "searchQuery">;

export type AchievementsFilters = {
  status: AchievementsStatuses;
};

export type AchievementsRequestParams = AchievementsQuery & {
  filters: {
    status?: AchievementsStatuses | string;
    categoryIds: number[];
  };
};

export type AchievementsFilterByCategory = {
  id: number;
  name: string;
  color: string;
};

export enum AchievementsSortBy {
  coins = "Coins",
  сreatedAt = "CreatedAt",
}
