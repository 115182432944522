import { AxiosError } from "axios";
import _debounce from "lodash/debounce";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { getErrorMessage } from "../../utils/methods";
import { toastError } from "utils/toasts";
import {
  OptionsSearch,
  SearchNavigateLinks,
  SearchRequest,
  SearchType,
} from "api/types/searchTypes";
import { loadGlobalSearch } from "api/searchApi";
import { Icon } from "utils/components";

const routePrefixMap: Record<SearchType, SearchNavigateLinks> = {
  [SearchType.User]: SearchNavigateLinks.Employee,
  [SearchType.Event]: SearchNavigateLinks.Event,
  [SearchType.Category]: SearchNavigateLinks.Category,
};

const SearchBar = () => {
  const { t } = useTranslation("layout");
  const { t: tCommon } = useTranslation("common");
  const navigate = useNavigate();

  const [data, setData] = useState<OptionsSearch | null>(null);
  const getSearchEntities = _debounce(
    (inputValue: string, callback: (options: OptionsSearch[]) => void) => {
      if (inputValue.length < 3) {
        callback([]);
        return;
      }
      const body: SearchRequest = {
        searchQuery: inputValue,
        filterCurrentUser: true,
      };

      loadGlobalSearch(body)
        .then((response) => {
          const options = response.data.items.map((item) => {
            const { svgClass, href } = getIconInfo(item.type);
            const label = (
              <div className="search-label">
                <Icon svgClass={svgClass} href={href} />
                {item.text}
              </div>
            );
            return {
              value: item.id,
              label,
              type: item.type,
            };
          });
          callback(options);
        })
        .catch((e) => {
          const errorMessage = getErrorMessage(
            e as AxiosError,
            t("errorWhileSearching") as string
          );
          toastError(errorMessage);
          callback([]);
        });
    },
    750
  );

  const handleSelectChange = async (newValue: OptionsSearch | null) => {
    if (newValue) {
      handleNavigation(newValue.type, newValue.value);
      setData(null);
    }
  };

  const handleNavigation = async (type: SearchType, value: number) => {
    const routePrefix = routePrefixMap[type];
    if (routePrefix) {
      navigate(`${routePrefix}/${value}`);
    }
  };

  const getIconInfo = (type: SearchType) => {
    const iconMap = {
      [SearchType.User]: { svgClass: "ic-person", href: "#person" },
      [SearchType.Event]: { svgClass: "ic-calendar ", href: "#calendar" },
      [SearchType.Category]: { svgClass: "ic-category", href: "#category" },
    };

    return iconMap[type] || { svgClass: "", href: "" };
  };

  return (
    <AsyncSelect
      value={data}
      isClearable={true}
      loadOptions={getSearchEntities}
      className="asyncSelect__container layoutSearchBar"
      classNamePrefix="asyncSelect"
      placeholder={tCommon("startTypingToSearch")}
      onChange={handleSelectChange}
      noOptionsMessage={() => <>{tCommon("select_noItems") as string}</>}
    />
  );
};

export default SearchBar;
